.outerContainer {
    width: 100%;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
}

.icon {
    height: 32px;
    width: 32px;
    background-size: auto;
    background-repeat: no-repeat;
    margin-left: 5px;
}