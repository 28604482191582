.container {
    background-color: #363636;
}

.innerContainer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    color: white;
}

.section {
    flex: 1;
    width: 80%;
    margin: 40px;
}

.subsection {
}

@mixin pic($num) {
    .pic#{$num} {
        margin: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        
        width: 100%;
        background-image: url(/assets/help/help#{$num}.png);

        box-shadow: 5px 5px 10px rgba(204, 204, 204, 0.452);
    }
}

@for $i from 1 to 17 {
    @include pic($i);
}

.pic1 {
    width: 436px;
    height: 383px;
}

.pic2 {
    width: 499px;
    height: 335px;
}

.pic3 {
    width: 273px;
    height: 230px;
}

.pic4 {
    @extend .pic4;

    width: 29px;
    height: 24px;
    margin: unset;
    box-shadow: unset;
    display: inline-block;
}

.pic5 {
    @extend .pic5;

    width: 20px;
    height: 24px;
    margin: unset;
    box-shadow: unset;
    display: inline-block;
}

.pic6 {
    @extend .pic6;

    width: 32px;
    height: 24px;
    margin: unset;
    box-shadow: unset;
    display: inline-block;
}

.pic7 {
    width: 281px;
    height: 373px;
}

.pic8 {
    width: 265px;
    height: 156px;
}

.pic9 {
    width: 311px;
    height: 140px;
}

.pic10 {
    width: 225px;
    height: 119px;
}

.pic11 {
    width: 704px;
    height: 179px;
}

.pic12 {
    width: 430px;
    height: 692px;
}

.pic13 {
    width: 614px;
    height: 648px;
}

.pic14 {
    width: 230px;
    height: 232px;
}

.pic15 {
    width: 224px;
    height: 307px;
}

.pic16 {
    width: 224px;
    height: 215px;
}

.alpha {
    list-style: lower-alpha;
}

.note {
    font-style: italic;
    margin-left: 10px;
}

.imageGroup {
    display: flex;
    flex-flow: row wrap;
}

.noBlobs {
    list-style: none;
}

.outsideNoBlobs {
    @extend .noBlobs;

    padding-left: 0px;
}