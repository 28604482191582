.pageHeader {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;

    margin-bottom: 20px;
}

.pageHeaderTitle {
    flex: 1 1;
    margin-bottom: 0px !important;
}

.pageHeaderButtons {
    flex: 0 0 550px;
    justify-self: flex-end;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
}