.container {
    display: flex;
    flex-direction: row;
}

.image {
    height: 30px;
    width: 30px;
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 7px;
}