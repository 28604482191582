@import '../../styles/pageHeader.scss';
@import '../../styles/frame.scss';

.header {
    flex: 1 1;
    font-weight: bold;
    font-size: 14px;
    color: black !important;
    text-transform: uppercase !important;
    margin-bottom: 0px !important;
}

.headerContainer {
    flex: 1 1;
}

.frameContainer {
    @extend .frame;
    max-width: 165px;
    margin-top: 3px;
}