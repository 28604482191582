.container {
}

.header {
    margin-left: -31px;
    margin-top: -31px;
    margin-right: -25px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row wrap;
}

.header > * {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.number {
    background: #00b71c 0% 0% no-repeat padding-box;
    text-align: center;
    font: Bold 22px/26px Arial;
    letter-spacing: 0;
    color: #f7f7f7;
    opacity: 1;
    padding: 5px;
    flex: 0 1 50px;
    border-top-left-radius: 4px;
}

.title {
    font-weight: bold;
    font-size: 14px;
}

.enabled {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.enabledLabel {
    margin-right: 10px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
}

.buttonContainer > * {
    height: 65px;
    flex: 1;
    background: #363636 0% 0% no-repeat padding-box;
    border-radius: 31px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;

    margin-top: 15px;

    cursor: pointer;
}

.heading {
    font-weight: bold;
}