@import '../../../styles/frame.scss';

.container {
    display: block;
    position: relative;
}

.dayLine {
    display: flex;
    width: 100%;
    flex-flow: row;
    background-clip: content-box;
}

.dayOfWeek {
    width: 30px;
    font-weight: bold;
}

.dots {
    flex: 1 0;
    background-image: url(/assets/dot.svg);
    background-size: 0.4%;
    background-repeat: repeat-x;
    background-position-y: 10px;
}

.frames {
    position: absolute;
    top: 0px;
    z-index: 1000;
    width: 100%;
    display: flex;
    flex-flow: row;
    height: 100%;
    padding-right:30px;
    margin-left: 30px;
}