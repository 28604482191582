.container {
    display: flex !important;
    flex-flow: row;   
    background-color: #363636;
    color: #FFFFFF;
    padding: 7px 20px !important;
    border-radius: 20px;
    width: fit-content;
    cursor: pointer;
    font-weight: bold;
    height: fit-content;
    font-size: 14px;
    align-items: center;
    max-width: fit-content;
}

.icon {
    height: 25px;
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: -10px;
}
