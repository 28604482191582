@mixin image($url, $width: 20, $height : 16) {
    height: $height + px;
    width: $width + px;
    background-size: auto;
    background-repeat: no-repeat;

    background-image: url($url);
}

.pageContainer {
    padding: 25px;
    min-height: 100%;
    flex-grow: 1;
}

.secondaryPageContainer {
    @extend .pageContainer;

    background-color: #F7F7F7;
}