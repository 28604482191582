.icon {
    height: 25px;
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 10px;
}

.checkmark-true {
    @extend .icon;
    background-image: url('/assets/ic-on-okay.svg');

}

.checkmark-false {
    @extend .icon;
    background-image: url('/assets/ic-off-cycle.svg');
}

.confirmed {
    display: flex;
    justify-content: center;
}