@import '../styles/pageHeader.scss';
@import '../styles/base.scss';

.itemControls {
    @extend .pageHeaderTitle;
    display: flex;
    flex-flow: row wrap;
    margin: -15px !important;
    align-items: center;
}

.itemControls > * {
    margin: 15px !important;
    width: 150px !important;
}

.page {
    margin-top: 15px;
}

.modalActions {
    flex: 0 0 350px;
    justify-self: flex-start;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    margin: -5px;
}

.modalActions > * {
    margin: 5px;
}

.filterForm {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.filterFormField {
    width: 48%;
}

.executedFilters {
    margin-bottom: 20px;
}

.executedFilter {
    display: flex;
    flex-flow: row;
    margin-top: 3px;
    margin-bottom: 3px;
}

.executedFilter > * {
    margin-right: 5px;
}

.executedFilterValue {
    font-weight: bold;
}

.close {
    cursor: pointer;
    @include image('/assets/id-remove-x.svg', 20, 20);
}

.container {
    width: 100%;
}