.container {
    flex: 1 0;
    font-family: monospace;
    margin-left: 30px;
    flex-basis: 100%;
}


.hours {
    display: flex;
}

.hours > * {
    flex: 1 0;
    display: flex;
    flex-direction: row;
}

.hashes > *:before {
    flex: 1 0;
    content: '|';
    font-weight: bold;
}

.hashes > *:nth-child(odd):before {
    font-size: 7px;
}

.hashes > *:nth-child(even):before {
    font-size: 5px;
}

.last {
    width: 100%;
    display: flex;
    flex-direction: row;   
}

.last > * {
    flex: 1 0;
}

.lastHash {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.lastHash:before {
    flex: 1 0;
    content: '|';
    font-size: 7px;
    font-weight: bold;
}

.times > * {
    margin-left: -1ch;
}

.big {
    margin-left: -2ch;
}

.lastTime {

}

.extended {
    position: absolute;
    right: 2ch;
}