@import '../styles/base.scss';
@import '../styles/form.scss';

.buttonRow {
    @extend .buttonRow;
    margin-top: 30px;
}

.menuItem {
    font-size: 20px !important;
    cursor: pointer;
    text-align: left;
}

.menu {
    margin-bottom: 30px !important;
}

.menu:hover {
    color: inherit!important;
}

.header {
    display: flex;   
}

.right {
    flex: 1;
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
}