@import './base.scss';

.row {
    display: flex;
    flex-direction: row;
    line-height: 16px;
    margin: -3px;
}

.row > * {
    margin: 3px;
}

@mixin remaining($percent) {
    .remaining-#{$percent} {
        @include image('/assets/ic-product-' + $percent + '.svg', 20, 20);
    }
}

@for $i from 0 to 5 {
    @include remaining($i * 25);
}

.on {
    @include image('/assets/ic-on-okay.svg');
}

.off {
    @include image('/assets/ic-off-alert.svg');
}

@mixin fan($speed) {
    .fan-#{$speed} {
        @include image('/assets/ic-fan-' + $speed + '.svg', 28);
    }
}

@for $i from 1 to 6 {
    @include fan($i);
}
    
@mixin intensity($intensity) {
    .intensity-#{$intensity}-on {
        @include image('/assets/ic-intensity-#{$intensity}-on.svg', 26);
    }

    .intensity-#{$intensity}-off {
        @include image('/assets/ic-intensity-#{$intensity}-off.svg', 26);
    }
}

@for $i from 1 to 6 {
    @include intensity($i);
}

@mixin offCycle($cycle) {
    .offCycle-#{$cycle} {
        @include image('/assets/ic-off-cycle-#{$cycle}.svg');
    }
}

@for $i from 1 to 4 {
    @include offCycle($i);
}
