.rows {
    display: flex;
    flex-direction: column;
}

.row {
    flex: 1;
    margin-left: -25px;
    margin-right: -25px;
    
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 30px;

    &:nth-child(even) {
        background-color: #EFEFEF;
    }
}

.buttonRow {
    flex: 1;
    margin-left: -25px;
    margin-right: -25px;
    
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 2px solid #EFEFEF;
}

.error {
    color: red;
    margin-top: 5px;
}