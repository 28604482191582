@import '../../styles/base.scss';

.details {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin-top: 15px;
    margin-bottom: 15px;
}

.details > * {
    margin-left: 5px!important;
    margin-right: 5px!important;
    justify-content: space-between;
}

.segment {
    background-color: #bdbaba!important;
    min-width: 215px;
}

.choose {
    @extend .segment;
    flex: 1 1;
}

:global(#root .copyOption) {
    max-width: inherit;
    width: 85%;
}

.list {
    @extend .segment;
    flex: 2 0 0;
}

.apply {
    @extend .segment;
    flex: 1 1;
}

.applyButton {
    width: 75%;
}

.deleteIcon {
    @include image('/assets/ic-cancel.svg');

    flex: 0 1 30px !important;
    align-self: flex-end;
    cursor: pointer;
}

.deviceDescription {
    display: flex;
}

.deviceDescription > * {
    flex: 1 1;
    height: 30px;
    margin: 5px;
    display: flex;
    align-items: center;
}

