.frame {
    background: #00b71c 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    color: white;
    flex-wrap: wrap;
    overflow: hidden;
}

.number {
    flex: 0 1 1;
    background: #009316 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.intensity {
    flex: 1 0;
    text-align: right;
    padding-right: 10px;
}