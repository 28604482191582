.container {
    background-color: #363636;
    height: 100vh;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    form {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    form > * {
        margin-bottom: 15px;
    }
}

.container > * {
    margin: 10px;
    max-width: 340px;
}

.logo {
    width: 340px;
    height: 80px;
    background-image: url(/assets/logo-prolitec-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.description {
    max-width: 800px;
    font-size: 20px;
    color: #c4c4c4;
}
