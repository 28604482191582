@import '../../styles/base.scss';
@import '../../styles/form.scss';
@import '../../styles/pageHeader.scss';

.dropZone {
    width: 25%;
    height: 100px;
    background-color: lightgrey;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.header {
    @extend .header;

    margin-top: 30px;
}

.instructionsContent {
    margin-left: 1rem;
    margin-bottom: 3rem;
}

.alphaList {
    list-style-type: lower-alpha;
}
