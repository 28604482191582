html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;
}

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    padding: 0;
    margin: 0;
    width: 100%;
}

.outerRouter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;
}

.innerRouter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ui.table thead th,
.ui.sortable.table thead th,
.ui.sortable.table thead th:hover,
.ui.sortable.table thead th.sorted,
.ui.sortable.table thead th.sorted:hover {
    background-color: #868686;
    color: white;
}

.ui.menu.secondary .item {
    font-size: 26px;
}

.ui.menu.secondary.inverted.pointing .active.item,
.ui.menu.secondary.pointing .active.item,
.ui.secondary.pointing.menu .active.item:hover {
    border-color: #f36b24;
    border-width: 4px;
}

.ui.pointing.secondary.menu {
    border: none;
}

.ui.header {
    color: #f36b24;
}

.ui.label {
    background-color: unset;
    color: #868686;
}

.ui.placeholder.segment {
    background: #f7f7f7;
    border-color: #f7f7f7;
    box-shadow: unset;
    min-height: unset;
    margin: inherit;
}

.ui.placeholder + .ui.placeholder {
    margin: inherit;
}

.ui.pagination.menu {
    border: none;
    box-shadow: none;
    background: inherit;
}

.ui.menu.pagination .item:before {
    display: none;
}

.ui.menu.pagination .item {
    margin: 2px;
}

.ui.menu.pagination .item[type='firstItem'],
.ui.menu.pagination .item[type='lastItem'] {
    display: none;
}

.ui.menu.pagination .item[type='prevItem'],
.ui.menu.pagination .item[type='nextItem'] {
    font-weight: bolder;
}

.ui.menu.pagination .item[type='pageItem'] {
    border-radius: 50%;
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
}

.ui.menu.pagination .item.active {
    background-color: inherit;
    border: 1px solid #363636;
    color: #363636;
}

.ui.dropdown {
    border: 1px solid #c4c4c4;
    padding: 10px 15px;
}

.ui.modal {
    background-color: #f7f7f7;
    padding: 10px;
}

.ui.modal > .content {
    background-color: #f7f7f7;
}

.ui.modal > .actions {
    background-color: #f7f7f7;
    border-top: none;
}

.ui.primary.button,
.ui.primary.button:active,
.ui.primary.button:focus {
    background-color: #f36b24;
}

.ui.primary.button:hover {
    background-color: #4b4b4b;
}

.ui.button,
.ui.button:active,
.ui.button:focus,
.ui.button:hover {
    background-color: #4b4b4b;
    border-radius: 20px;
    padding: 10px 16px;
    width: fit-content;
    font-weight: bold;
    height: fit-content;
    display: flex;
    color: #ffffff;
}

a,
a:hover {
    color: #f36b24;
}

.ui.form.dark,
.ui.form.dark .field > label {
    color: #c4c4c4;
}

.ui.form .fields > * {
    margin: 6px;
}

.ui.form .field > label {
    font-weight: normal;
}

.ui.form > div > label {
    display: block;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.ui.dropdown {
    width: 100%;
    background-color: white;
    height: 38px;
    display: flex;
    padding: unset;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    border-radius: 0.28571429rem;
}

.ui.dropdown .text {
    flex: 1 1 auto;
}

.ui.checkbox:not(.toggle) input:checked ~ label:after {
    content: none;
}

.ui.checkbox:not(.toggle) input:checked ~ label:before {
    background: none;
    border: none;
    background-image: url(/assets/ic-checkbox-selected.svg);
    background-repeat: no-repeat;
}

.ui.checkbox:not(.toggle) input ~ label:before {
    background: none;
    border: none;
    background-image: url(/assets/ic-checkbox-unselected.svg);
    background-repeat: no-repeat;
}

.ui.checkbox:not(.toggle) label:before {
    top: unset;
    width: 20px;
    height: 20px;
    transition: none;
    border: none;
    background: none;
}

.ui.checkbox:not(.toggle) input.hidden,
.ui.checkbox:not(.toggle) input.hidden:hover {
    display: none;
}

.ui.checkbox:not(.toggle) label {
    display: inline;
    line-height: 20px;
}

.ui.checkbox:not(.toggle) label:hover,
.ui.checkbox:not(.toggle) + label:hover,
.ui.checkbox:not(.toggle) label:hover::before,
.ui.checkbox:not(.toggle) label:focus,
.ui.checkbox:not(.toggle) label:focus::before,
.ui.checkbox:not(.toggle) label:active,
.ui.checkbox:not(.toggle) label:active::before {
    top: unset;
    width: 20px;
    height: 20px;
    background: none;
    background-color: inherit;
    border: none;
    background-image: url(/assets/ic-checkbox-unselected.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.ui.checkbox.radio:not(.toggle) input:checked ~ label:after {
    content: none;
    display: none;
}

.ui.checkbox.radio:not(.toggle) input:checked ~ label:before {
    background: none;
    border: none;
    background-image: url(/assets/ic-checkbox-selected.svg);
    background-repeat: no-repeat;
}

.ui.checkbox.radio:not(.toggle) input ~ label:before {
    background: none;
    border: none;
    background-image: url(/assets/ic-checkbox-unselected.svg);
    background-repeat: no-repeat;
}

.ui.checkbox.radio:not(.toggle) label:before {
    top: unset;
    width: 20px;
    height: 20px;
    transition: none;
    border: none;
    background: none;
}

.ui.checkbox.radio:not(.toggle) input.hidden,
.ui.checkbox.radio:not(.toggle) input.hidden:hover {
    display: none;
}

.ui.checkbox.radio:not(.toggle) label {
    display: inline;
    line-height: 20px;
}

.ui.checkbox.radio:not(.toggle) label:hover,
.ui.checkbox.radio:not(.toggle) + label:hover,
.ui.checkbox.radio:not(.toggle) label:hover::before,
.ui.checkbox.radio:not(.toggle) label:focus,
.ui.checkbox.radio:not(.toggle) label:focus::before,
.ui.checkbox.radio:not(.toggle) label:active,
.ui.checkbox.radio:not(.toggle) label:active::before {
    top: unset;
    width: 20px;
    height: 20px;
    background: none;
    background-color: inherit;
    border: none;
    background-image: url(/assets/ic-checkbox-unselected.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.ui.toggle.checkbox input:checked,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #00b71c !important;
}

.ui.search.dropdown input.search {
    height: 38px;
    width: 90%;
}

.PhoneInputCountrySelect:disabled {
    display: none!important;
}