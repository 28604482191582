.container {
    display: flex;
    flex-flow: column;

    margin: -5px;

    & button {
        margin: 5px !important;
    }
}

.horizontalContainer {
    @extend .container;
    flex-flow: row wrap;
}
