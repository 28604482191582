.editIcon {
    background-image: url(/assets/ic-edit-gray.svg);
    height: 26px;
    width: 26px;
    right: 5px;
    top: 5px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.removeIcon {
    background-image: url(/assets/id-remove-x-gray.svg);
    height: 26px;
    width: 26px;
    right: 5px;
    top: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.centerCheck {
    margin-left: -20px;
}