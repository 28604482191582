.image {
    height: 16px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
}

.horizontal {
    min-width: 65px;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.horizontal > * {
    margin-right: 5px;
}

.ascending:after {
    content: '\f0d8';
    align-self: center;
    text-align: right;
    flex: 1;
    font-family: Icons;
}

.descending:after {
    content: '\f0d7';
    align-self: center;
    text-align: right;
    flex: 1;
    font-family: Icons;
}

.container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}