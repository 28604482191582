.container {
    display: flex;
    flex-direction: row;
    width: 100% !important;
}

.container > * {
    flex: 1 !important;
    margin: 10px;
}

.logo {
    height: 46px;
    background-image: url(/assets/logo-prolitec-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: auto;
    margin-bottom: auto;
}

.item {
    display: flex;
    justify-content: center;
}

.menu {
    border: none !important;
    margin: inherit !important;
}

.topMenu {
    @extend .menu;

    height: 25px !important;
    min-height: initial !important;
    margin-right: 10px !important;
}

.link {
    font-size: 16px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    padding: inherit !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}