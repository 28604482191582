.link {
    margin: 7px auto;
}

.formRow {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.formRow > * {
    flex: 1;
}