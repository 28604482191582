@import '../../styles/base.scss';
@import '../../styles/devices.scss';
@import '../../styles/pageHeader.scss';
@import '../../styles/frame.scss';

.detailsContainer {
    display: flex;
    flex-flow: row wrap;

    margin: -12px;
}

.detailsContainer > * {
    margin: 12px;
}

.demographics {
    flex: 3;
    min-width: 350px;
}

.product {
    flex: 1;
    min-width: 250px;
}

.schedule {
    margin-top: 24px;
}

.notifications {
    margin-top: 24px;
}

.header {
    flex: 1 1;
    font-weight: bold;
    font-size: 14px;
    color: black !important;
    text-transform: uppercase !important;
    margin-bottom: 0px !important;
}