@import '../../styles/base.scss';
@import '../../styles/pageHeader.scss';
@import '../../components/devices/Intensity.module.scss';

.numberCell {
    
    background-color: #868686;
    text-align: center !important;
    color: #f7f7f7;
    font-size: 22px;
    font-weight: bold;
}

.activeNumberCell {
    @extend .numberCell;

    background-color: #00B71C;
}

.active {
    background: #00b71c3f 0% 0% no-repeat padding-box;   
}

.editIcon {
    background-image: url(/assets/ic-edit-gray.svg);
    height: 26px;
    width: 26px;
    right: 5px;
    top: 5px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}