.container {
    display: flex !important;
    flex-flow: row wrap !important;
}

.container > * {
    flex: 1;
    padding: 10px;
}

.editIcon {
    position: absolute;
    background-image: url(/assets/ic-edit-dark.svg);
    height: 26px;
    width: 26px;
    right: 5px;
    top: 5px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}