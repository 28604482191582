.table {
    border: none !important;

    & tr:nth-child(odd) {
        background-color: #EFEFEF;
    }
    
    & tr:nth-child(even) {
        background-color: #F7F7F7;
    }
}

.headerRow th {
    background-color: white !important;
    color: black !important;
    font-weight: normal !important;
    border: none !important;
}