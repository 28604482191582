.title {
    font-weight: bold;
    font-size: 14px;
    color: black !important;
    text-transform: uppercase!important;
    margin-bottom: 0px !important;
}

.divider {
    height: 2px;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.subtitle {
    margin-left: 15px;
}