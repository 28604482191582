.container {
    margin-top: 20px;
}

.q {
    font-weight: bolder;
}

.a {
    margin-top: 10px;
    margin-left: 10px;
}