.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 12px;
    padding: 15px;
}

.container > * {
    flex: 1;
}